import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row gy-5 g-xl-8" }
const _hoisted_2 = { class: "col-xxl-4" }
const _hoisted_3 = { class: "col-xxl-4" }
const _hoisted_4 = { class: "col-xxl-4" }
const _hoisted_5 = { class: "row gy-5 g-xl-8" }
const _hoisted_6 = { class: "col-xxl-6" }
const _hoisted_7 = { class: "col-xxl-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MixedWidget12 = _resolveComponent("MixedWidget12")!
  const _component_MixedWidget7 = _resolveComponent("MixedWidget7")!
  const _component_MixedWidget10 = _resolveComponent("MixedWidget10")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_MixedWidget12, {
          title: "Total Orders",
          "widget-classes": "card-xl-stretch mb-xl-8",
          "widget-color": "primary",
          "chart-height": "150",
          value: "2350 Orders"
        })
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_MixedWidget12, {
          title: "Partners",
          "widget-classes": "card-xl-stretch mb-xl-8",
          "widget-color": "danger",
          "chart-height": "150",
          value: "235"
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_MixedWidget12, {
          title: "Cars",
          "widget-classes": "card-xl-stretch mb-xl-8",
          "widget-color": "warning",
          "chart-height": "150",
          value: "521"
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_MixedWidget7, {
          "widget-classes": "card-xxl-stretch mb-5 mb-xl-8",
          "chart-color": "success",
          "chart-height": "250"
        })
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_MixedWidget10, {
          "widget-classes": "card-xxl-stretch mb-5 mb-xl-8",
          "chart-color": "success",
          "chart-height": "250"
        })
      ])
    ])
  ], 64))
}