
import { defineComponent } from 'vue';
import { getCSSVariableValue } from '@/assets/ts/_utils';

export default defineComponent({
  name: 'widget-12',
  props: {
    widgetClasses: String,
    chartColor: String,
    chartHeight: String,
  },
  setup(props) {
    const labelColor = getCSSVariableValue('--bs-gray-500');
    const borderColor = getCSSVariableValue('--bs-gray-200');
    const lineColor = (color) => getCSSVariableValue('--bs-' + color);
    const baseColors = [
      lineColor('primary'),
      lineColor('success'),
      lineColor('warning'),
      lineColor('danger'),
      lineColor('info'),
      lineColor('secondary'),
      lineColor('dark'),
    ];

    const series = [
      {
        name: 'Net Profit',
        data: [150, 60, 70, 80, 60, 50, 70],
      },
    ];

    const chartOptions = {
      chart: {
        fontFamily: 'inherit',
        type: 'bar',
        height: props.chartHeight,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          distributed: true,
          columnWidth: '40%',
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        categories: [
          'Phuket',
          'Phangnga',
          'Krabi',
          'USA',
          'Japan',
          'Bangkok',
          'EU',
        ],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            colors: labelColor,
            fontSize: '12px',
          },
        },
      },
      yaxis: {
        y: 0,
        offsetX: 0,
        offsetY: 0,
        labels: {
          style: {
            colors: labelColor,
            fontSize: '12px',
          },
        },
      },
      fill: {
        type: 'solid',
      },
      tooltip: {
        style: {
          fontSize: '12px',
        },
        y: {
          formatter: function (val) {
            return '$' + val + ' revenue';
          },
        },
      },
      colors: baseColors,
      grid: {
        padding: {
          top: 10,
        },
        borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
    };

    return {
      chartOptions,
      series,
    };
  },
});
