
import { defineComponent } from 'vue';
import { getCSSVariableValue } from '@/assets/ts/_utils';

export default defineComponent({
  name: 'widget-12',
  props: {
    title: String,
    value: String,
    widgetClasses: String,
    widgetColor: String,
    chartHeight: String,
  },
  setup(props) {
    const color = getCSSVariableValue('--bs-' + props.widgetColor);
    const lightColor = getCSSVariableValue('--bs-light-' + props.widgetColor);
    const labelColor = getCSSVariableValue('--bs-' + 'gray-800');
    const strokeColor = getCSSVariableValue('--bs-' + 'gray-300');

    const series = [
      {
        name: props.title,
        data: [15, 25, 15, 40, 20, 50],
      },
    ];

    const chartOptions = {
      chart: {
        fontFamily: 'inherit',
        type: 'area',
        height: props.chartHeight,
        sparkline: {
          enabled: true,
        },
        toolbar: {
          show: false,
        },
      },
      plotOptions: {},
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: 'solid',
        opacity: 1,
      },
      stroke: {
        curve: 'smooth',
        show: true,
        width: 4,
        colors: [color],
      },
      xaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            colors: labelColor,
            fontSize: '12px',
          },
        },
        crosshairs: {
          position: 'front',
          stroke: {
            color: strokeColor,
            width: 1,
            dashArray: 3,
          },
        },
        tooltip: {
          enabled: true,
          formatter: undefined,
          offsetY: 0,
          style: {
            fontSize: '12px',
          },
        },
      },
      tooltip: {
        style: {
          fontSize: '12px',
        },
        y: {
          formatter: (val) => {
            return val;
          },
        },
      },
      colors: [lightColor],
      grid: {
        borderColor: strokeColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      markers: {
        strokeColor: strokeColor,
        strokeWidth: 3,
      },
    };

    return {
      series,
      chartOptions,
    };
  },
});
