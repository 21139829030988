
import { defineComponent } from 'vue';
import { getCSSVariableValue } from '@/assets/ts/_utils';

export default defineComponent({
  name: 'widget-7',
  props: {
    widgetClasses: String,
    chartColor: String,
    chartHeight: String,
  },
  setup(props) {
    const labelColor = getCSSVariableValue('--bs-' + 'gray-500');
    const lineColor = (color) => getCSSVariableValue('--bs-' + color);
    const baseColors = [
      lineColor('primary'),
      lineColor('success'),
      lineColor('warning'),
      lineColor('danger'),
    ];

    const series = [
      {
        name: 'Car Rental',
        data: [1.5, 2.5, 1.5, 4, 2.0, 5],
      },
      {
        name: 'Car Rental with driver',
        data: [2.5, 1.5, 4.5, 3, 6, 4],
      },
      {
        name: 'Driver rental',
        data: [5.5, 4.5, 7.5, 1, 2, 3],
      },
      {
        name: 'Sharing Car',
        data: [5, 5, 3.5, 6, 4, 1],
      },
    ];

    const chartOptions = {
      chart: {
        fontFamily: 'inherit',
        type: 'line',
        height: props.chartHeight,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {},
      legend: {
        position: 'top',
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: 'solid',
        opacity: 1,
      },
      stroke: {
        curve: 'smooth',
        show: true,
        width: 3,
        colors: baseColors,
      },
      xaxis: {
        categories: ['1/1', '2/1', '3/1', '4/1', '5/1', '6/1', '7/1'],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            colors: labelColor,
            fontSize: '12px',
          },
        },
        crosshairs: {
          position: 'front',
          stroke: {
            color: baseColors,
            width: 1,
            dashArray: 3,
          },
        },
        tooltip: {
          enabled: true,
          formatter: undefined,
          offsetY: 0,
          style: {
            fontSize: '12px',
          },
        },
      },
      yaxis: {
        decimalsInFloat: 0,
        labels: {
          style: {
            colors: labelColor,
            fontSize: '12px',
          },
        },
      },
      states: {
        normal: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        hover: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none',
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: '12px',
        },
        y: {
          formatter: (val) => {
            return '฿' + val + ' millions';
          },
        },
      },
      colors: baseColors,
      grid: {
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      markers: {
        size: 4,
        strokeWidth: 0,
        hover: {
          size: 6,
        },
      },
    };

    return {
      chartOptions,
      series,
    };
  },
});
