import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d322cb46"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card-body d-flex flex-column" }
const _hoisted_2 = { class: "d-flex flex-column flex-grow-1" }
const _hoisted_3 = { class: "d-flex justify-content-between" }
const _hoisted_4 = { class: "text-dark fw-bolder fs-3 px-6 pt-6" }
const _hoisted_5 = { class: "text-primary fw-bolder fs-3 px-6 pt-6 text-uppercase" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.widgetClasses, "card"]),
    style: _normalizeStyle(`background-color: ${_ctx.widgetColor}`)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.title), 1),
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.value), 1)
        ]),
        _createVNode(_component_apexchart, {
          class: "mixed-widget-13-chart",
          options: _ctx.chartOptions,
          series: _ctx.series,
          height: _ctx.chartHeight,
          type: "area"
        }, null, 8, ["options", "series", "height"])
      ])
    ])
  ], 6))
}